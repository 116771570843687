<template>
	<section>
		<section class="search-container">
			<a-form layout="inline" class="m-t-14">
				<a-form-item label="预警名称：">
					<a-input
						class="search-input"
						placeholder="请输入"
						v-model="formSearch.ruleName"
					/>
				</a-form-item>
				<a-form-item label="预警类型：">
					<a-select
						class="search-input"
						placeholder="全部"
						v-model="formSearch.alarmType"
					>
						<a-select-option value="">全部</a-select-option>
						<a-select-option
							v-for="item in typeList"
							:key="item.id"
							:value="item.id"
						>
							{{ item.typeName }}
						</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="状态：">
					<a-select
						class="search-input"
						placeholder="全部"
						v-model="formSearch.addStatus"
					>
						<a-select-option value="">全部</a-select-option>
						<a-select-option value="0">未添加</a-select-option>
						<a-select-option value="1">已添加</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" class="m-r-8" @click="btnQuery"
						><a-icon type="search" />查询</a-button
					>
					<a-button class="m-r-8" @click="btnReset"
						><a-icon type="reload" />重置</a-button
					>
				</a-form-item>
			</a-form>
		</section>
		<section class="list-table-container m-t-18">
			<a-table
				size="small"
				:columns="columns"
				:data-source="tableData"
				:pagination="{ total, hideOnSinglePage: true }"
				@change="pageNumChange"
			>
				<span slot="addStatus" slot-scope="text">
					{{ text === 1 ? '已添加' : '未添加' }}
				</span>
				<a
					slot="action"
					slot-scope="text, record"
					href="javascript: void(0)"
					@click="editAlarmRule(record)"
				>
					{{ record.addStatus === 1 ? '移除' : '添加' }}
				</a>
			</a-table>
		</section>
	</section>
</template>

<script>
	export default {
		name: 'AlarmRuleDetail',
		data() {
			return {
				form: this.$form.createForm(this),
				typeList: [],
				formSearch: {
					ruleName: '',
					alarmType: '',
					addStatus: ''
				},
				searchData: {
					ruleName: '',
					alarmType: '',
					addStatus: '',
					current: 1,
					size: 10
				},
				columns: [
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center'
					},
					{
						title: '预警名称',
						dataIndex: 'ruleName',
						align: 'center'
					},
					{
						title: '预警规则',
						dataIndex: 'ruleTemplate',
						align: 'center'
					},
					{
						title: '预警类型',
						dataIndex: 'typeName',
						align: 'center'
					},
					{
						title: '状态',
						dataIndex: 'addStatus',
						align: 'center',
						scopedSlots: { customRender: 'addStatus' }
					},
					{
						title: '操作',
						dataIndex: 'action',
						align: 'center',
						scopedSlots: { customRender: 'action' }
					}
				],
				tableData: [],
				total: 0,
				editVisible: false
			}
		},
		mounted() {
			this.getAlarmTypeAll()
			this.queryAlarmRule()
		},
		methods: {
			getAlarmTypeAll() {
				this.$api.getAlarmTypeAll().then((res) => {
					if (res.code === 200) {
						this.typeList = res.data
					}
				})
			},
			queryAlarmRule() {
				this.$api.queryAlarmRule(this.searchData).then((res) => {
					if (res.code === 200) {
						this.tableData = (res.data && res.data.records)
							? res.data.records.map((item, index) => {
									item.key =
										(this.searchData.current - 1) * this.searchData.size +
										index +
										1
									return item
							  })
							: []
						this.total = (res.data && res.data.total) || 0
					}
				})
			},
			pageNumChange(pagination) {
				this.searchData.current = pagination.current
				this.queryAlarmRule()
			},
			btnQuery() {
				this.searchData.ruleName = this.formSearch.ruleName
				this.searchData.alarmType = this.formSearch.alarmType
				this.searchData.alarmGrade = this.formSearch.alarmGrade
				this.searchData.addStatus = this.formSearch.addStatus
				this.searchData.current = 1
				this.queryAlarmRule()
			},
			btnReset() {
				this.searchData.ruleName = this.formSearch.ruleName = ''
				this.searchData.alarmType = this.formSearch.alarmType = ''
				this.searchData.alarmGrade = this.formSearch.alarmGrade = ''
				this.searchData.addStatus = this.formSearch.addStatus = ''
				this.searchData.current = 1
				this.queryAlarmRule()
			},
			editAlarmRule(record) {
				this.dataSelected = record
				this.$api
					.handleAlarmRule({
						id: this.dataSelected.id,
						addStatus: record.addStatus === 1 ? 0 : 1
					})
					.then((res) => {
						if (res.code === 200) {
							this.queryAlarmRule()
						}
					})
					.catch((err) => {
						console.log(err)
					})
			},
			btnConfirm() {
				this.$api
					.handleAlarmRule({
						id: this.dataSelected.id,
						addStatus: 1
					})
					.then((res) => {
						if (res.code === 200) {
							this.editVisible = false
							this.queryAlarmRule()
						}
					})
					.catch((err) => {
						console.log(err)
					})
			},
			btnCancel() {
				this.editVisible = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	.search-input {
		width: 120px;
	}
</style>
